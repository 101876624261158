import React, { Fragment } from "react"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as yup from "yup"

import Button from "../components/common/Button"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useReducer, useCallback } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPeopleCarry } from "@fortawesome/pro-light-svg-icons/faPeopleCarry";


function reducer(state, action) {
    switch (action.type) {
      case 'SENDING':
        return 'SENDING';
      case 'SENT':
        return 'SENT';
      default:
        return '';
    }
  }

export default function BecomeAServiceProvider() {
  const [state, dispatch] = useReducer(reducer);
  const send = useCallback(async (body) => {
    dispatch({ type: 'SENDING'});
    let fetchUrl = 'https://junkremovaldispatchem.azurewebsites.net/api/Email?code=1QEfW0pDiinLWFjWc/gTcIc3a5YcM5BqmVRp4JWF8CAgAkqqkBvr0w==';
    debugger;
    if(process.env.NODE_ENV === 'development') {
      fetchUrl += "&dev=1"
    }
    await fetch(fetchUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify(body)
      });
      dispatch({ type: 'SENT'});  
    }, [dispatch])
    if(state === "SENDING") {
        return (
            <Layout fluid={false}><h2>Submitting...</h2></Layout>
        )
    }
    if(state === "SENT") {
        return <Layout fluid={false}><h2>Thank you for submitting your information to Junk Removal Dispatch.  We are excited to review your information.  A company representative will be in touch with you soon.</h2></Layout>
    }
  return (
    <Fragment>
      <SEO title="Frequently Asked Questions" />
      <Layout fluid={false}>
        <h1>Becoming a Service Provider</h1>
        <div><FontAwesomeIcon icon={faPeopleCarry} size="lg" color="#000099" style={{marginLeft: "auto", display: "block"}}/></div>
        <hr />
        <p>Joining our network has many benefits.  The main benefit is that we will provide you will revenue…plain and simple.</p>
        <p><strong>Junk Removal</strong> - Our marketplace does all the work for you in regards to screening, data collection, and scheduling of the work for you.  All you need to do is adhere to the high level of customer service and performance standards that will be laid out to you.</p>
        <p><strong>Profile Page </strong> - Build a profile page with your services, pricing, ratings, hours and relevant information and sit back and enjoy the show.  We will get to work finding you </p>
        <p><strong>Fees</strong> - The fee we will charge you is 30% of your normal rate.  The fee is cost justified once you realized an increased volume of business.  You will more than make up for it.  Also, we only receive the 30% on the first order from your client.  When they order from you a second time, there is no fee…ever again.  In essence, your client acquisition cost is extremely low.</p>
        <p><strong>Contract</strong> -   There is no monthly fee.  If you want to quit, there is no termination fee.  As an added feature, you can always pause your service for varies reasons.  If you are going on vacation or just need a breather, use the pause feature</p>
        <h2>List My Company on Junk Removal Dispatch</h2>
        <Formik
          initialValues={{
              insurance: false,
              workmansComp: false,
              yearStarted: 2015
          }}
          onSubmit={send}
          validationSchema={yup.object().shape({
            firstName: yup.string("First name").required("required"),
            lastName: yup.string("Last name ").required("required"),
            email: yup.string().email("Email").required("required"),
            buisnessName: yup.string().required(),
            yearStarted: yup.number("Year started").required("required"),
            phone: yup.string().required(),
            address: yup.string().required("Address required"),
            city: yup.string().required("City required"),
            state: yup.string().required("State required"),
            zip: yup.number().required(),
            crews: yup.number().required(),
            miles: yup.number().required(),
            insurance: yup.bool().required(),
            workmansComp: yup.bool().required(),
            howSoon: yup.date("Invalid date").required("Date Required")
          })}
        >
          <Form>
              <label>First Name: <Field name="firstName" type="text" /></label><ErrorMessage name="firstName" /><br /><br />
              <label>Last Name: <Field name="lastName" type="text" /></label><ErrorMessage name="lastName" /><br /><br />
              <label>Email: <Field name="email" type="email" /></label><ErrorMessage name="email" /><br /><br />
              <label>Business Name: <Field name="buisnessName" type="text" /></label><ErrorMessage name="buisnessName" /><br /><br />
              <label>Year started: <Field name="yearStarted" type="number" /></label><ErrorMessage name="yearStarted" /><br /><br />
              <label>Phone: <Field name="phone" type="text" /></label><ErrorMessage name="phone" /><br /><br />
              <label>Address: <Field name="address" type="text" /></label><ErrorMessage name="address" /><br /><br />
              <label>City: <Field name="city" type="text" /></label><ErrorMessage name="city" /><br /><br />
              <label>State: <Field name="state" type="text" /></label><ErrorMessage name="state" /><br /><br />
              <label>Zip: <Field name="zip" type="number" /></label><ErrorMessage name="zip" /><br /><br />
              <label>How many crews can you provide: <Field name="crews" type="number" /></label><ErrorMessage name="crews" /><br /><br />
              <label>How many miles can you travel for jobs: <Field name="miles" type="number" /></label><ErrorMessage name="miles" /><br /><br />
              <label>Do you have business insurance: <Field name="insurance" type="checkbox" /></label><ErrorMessage name="insurance" /><br /><br />
              <label>Do you have worker’s comp: <Field name="workmansComp" type="checkbox" /></label><ErrorMessage name="workmansComp" /><br /><br />
              <label>How soon can you start: <Field name="howSoon" type="date" /></label><ErrorMessage name="howSoon" /><br /><br />
            <Button type="submit">Submit</Button><br />
          </Form>
        </Formik>
      </Layout>
    </Fragment>
  )
}
